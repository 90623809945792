<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-card class="my-card m-0">
                    <b-table 
                    striped
                    small
                    id="tariffTable"
                    bordered
                    selectable
                    selected-variant="primary"
                    select-mode="single"
                    responsive="sm"
                    :items="tariffPerformer"
                    :fields="fields"
                    @row-clicked="clicked"
                    @row-contextmenu="handleContextMenuEvent"
                    head-row-variant="primary"
                    >
                    <template #cell(blocked)="data">
                        <span>
                            {{data.item.blocked === 1 ? 'Да' : 'Не'}}
                        </span>
                    </template>
                    <template #cell(sentings)="data">
                        <span 
                        class="reference-field"
                        @dblclick="getSentings(data)" 
                        >
                            (***)
                        </span>
                    </template>
                    <template #cell(history_version)="data">
                        <span
                        class="reference-field"
                        @click="getHistory_version(data)"
                        >
                            (***)
                        </span>
                    </template>
                    <template #cell(allowance)="data">
                        <span
                        class="reference-field"
                        @click="getAllowance(data)">
                            (***)
                        </span>
                    </template>
                    <template #cell(district_surcharge)="data">
                        <span
                        class="reference-field"
                        @click="getDistrict_surcharge(data)">
                            (***)
                        </span>
                    </template>
                    <!-- class="reference-field"
                    @click="getLegal_entities(data)" = legal_entities -->
                    <template #cell(legal_entities)="data">
                        <span
                        >
                            {{ data.item.legal_entities === 1 ? 'Да' : 'Не'}}
                        </span>
                    </template>
                     <template #cell(type_tariff_performer)="data">
                       {{ data.item.type_tariff_performer_id }}
                     </template>
                </b-table>
                <vue-context ref="menu">
                    <li>
                        <b-link
                            class="d-flex align-items-center"
                            @click="autoUpdate"
                            >
                            <img :src="$store.state.updates.update ? iconsCheck : iconsSquare">
                            <span class="ml-1">Автообновление</span>
                        </b-link>
                    </li>
                </vue-context>
            </b-card>
        </b-col>
        </b-row>
    </div>
</template>

<script>
import VueContext from 'vue-context'
import { resizeable } from "@/utils/TableFunctions/resizeable.js"
import { cellSelect } from "@/utils/TableFunctions/cellSelect";

export default {
    components: {
        VueContext,
    },
    props: ['tariffPerformer'],
    data() {
        return {
            fields: [
            { key: 'id', label: 'ID', sortable: true, thStyle: {width: '50px'} },
            { key: 'division', label: 'Подразделение', sortable: true, thStyle: {width: '150px'}},
            { key: 'sort_number', label: 'Порядок', sortable: true, thStyle: {width: '50px'}},
            { key: 'name', label: 'Наименование', sortable: true, thStyle: {width: '150px'}},
            { key: 'blocked', label: 'Блокирован', sortable: true, thStyle: {width: '50px'}},
            { key: 'type_tariff_performer', label: 'Тип', sortable: true, thStyle: {width: '150px'}},
            { key: 'sentings', label: 'Настройка', sortable: true, thStyle: {width: '50px'}},
            { key: 'car_class', label: 'Классы авто', sortable: true, thStyle: {width: '150px'}},
            // { key: 'history_version', label: 'История версий', sortable: true, thStyle: {width: '50px'}},
            // { key: 'allowance', label: 'Водители', sortable: true, thStyle: {width: '50px'}},
            // { key: 'district_surcharge', label: 'Изменения', sortable: true, thStyle: {width: '50px'}},
            { key: 'legal_entities', label: 'Для юр.лиц', sortable: true, thStyle: {width: '50px'}},
            { key: 'basis', label: 'Основной', sortable: true, thStyle: {width: '50px'}},
            { key: 'dop_info', label: 'Доп.инфо', sortable: true, thStyle: {width: '100px'} },
        ],
        iconsSquare: require('@/assets/images/icons/square.svg'),
        iconsCheck: require('@/assets/images/icons/checkSquareIcon.svg')
        }
    },
    mounted(){
        resizeable()
    },
    methods: {
        handleContextMenuEvent(item, index, event){
           event.preventDefault();
           this.$refs.menu.open(event)
        },
        getSentings(data) {
            this.$store.commit('draggableTab/ADD_TAB',['Настройки тарифа ' + data.item.id, `/tariff/settings/work`, data.item.id])
            this.$router.push({name: 'tariff/settings/work', query:{filter: data.item.id}})
        },
        getHistory_version(data) {
            console.log('efwefwe');
        },
        getAllowance(data) {
            console.log('wefwe');
        },
        getDistrict_surcharge(data) {
            console.log('wefwefg');
        },
        getLegal_entities(data) {
            console.log('efwe');
        },
        autoUpdate() {
            this.$store.state.updates.update = !this.$store.state.updates.update;
            this.$emit('timerAfto');
        },
        clicked(item, index, event){
            this.$store.state.filterPlus.tableIndex = index

            const clickedElement = event.target;
            const clickedCell = clickedElement.closest('td');

            if (!clickedCell) {
                return;
            }

            const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(clickedCell);

            if (columnIndex < 0 || columnIndex >= this.fields.length) {
                return;
            }

            const clickedField = this.fields[columnIndex];
            this.$store.state.filterPlus.keyFilter = clickedField.key;
            this.$store.state.filterPlus.filterPlus = (event.target.innerHTML).trim()
            cellSelect()
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-context.scss';
.my-card .card-body{
    padding: 0px;
}
.my-card{
overflow: auto; 
height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card{
    overflow: auto; 
    height: 530px;
    }
}
table{
    outline: none; 
    table-layout: fixed; 
    -webkit-user-select: none;
    overflow: hidden;
    position: relative;
}
tr, td, th{
    padding: 0;
}
td, th{ 
    width: 100px; 
    white-space: nowrap; 
    overflow: hidden;  
    text-align: left; 
    box-sizing: border-box; 
    min-width: 60px;
    }
</style>