
<template>
    <div>
        <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
            <b-spinner variant="primary" label="Text Centered" />
        </div>
        <div v-else>
            <table-tariff-performer-work :tariffPerformer="tariffPerformer" @timerAfto="timerAfto"/>
        </div>
        <filter-modalVue :fields="fields" @sendToParent="sendToParent" :name="name"></filter-modalVue>
    </div>
</template>

<script>
import tableTariffPerformerWork from '@/views/component/Table/tableTariffPerformerWork.vue'
export default {
    props: {
        name: {
            type: String,
            default: '',
        },
    },
    components: {
        tableTariffPerformerWork,
    },
    data() {
        return {
            showPreloader: false,
            tariffPerformer: [],
            fields: [
                { key: 'checkbox', label: '', thStyle: {width: '30px'}},
                { key: 'id', label: 'ID', thStyle: {width: '30px'}},
                { key: 'division_name', label: 'Подразделение', thStyle: {width: '30px'}},
                { key: 'name', label: 'Наименование', thStyle: {width: '30px'}},
                { key: 'type_tariff_performer', label: 'Тип', thStyle: {width: '30px'}},
                { key: 'car_class_name', label: 'Классы авто', thStyle: {width: '30px'}},
                { key: 'sort_number', label: 'Порядок', thStyle: {width: '30px'}},
                { key: 'basis', label: 'Основной', thStyle: {width: '30px'}},
                { key: 'blocked', label: 'Блокирован', thStyle: {width: '30px'}}
            ],
        }
    },
    mounted() {
        this.openFilter()
        this.$store.commit('pageData/setdataCount', null)
    },
    methods: {
        refresh(){
            this.showPreloader = true
            this.$http.get(`${this.name}`)
                .then(res => {
                    this.tariffPerformer = res.data
                    this.$store.commit('pageData/setdataCount', this.tariffPerformer.length)
                    this.showPreloader = false
                    this.$store.commit('REFRESH_DATA', false)
                })
        },
        openFilter(){
            let allRoutes = this.$store.state.draggableTab.tabs
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            let arrayRoutes = []
            allRoutes.forEach(element => {
                arrayRoutes.push(element.path)
            });
            if (arrayRoutes.includes(this.$route.path) == true) {
                    this.showPreloader = true
                    this.$http
                    .get(`${this.name}`,{params: param})
                        .then(res => {
                            this.tariffPerformer = res.data
                            this.$store.commit('pageData/setdataCount', this.tariffPerformer.length)
                            this.showPreloader = false
                            this.$store.commit('REFRESH_DATA', false)
                        })
            }else if(arrayRoutes.includes(this.$route.path) == false){
                this.$bvModal.show(this.$route.name + 'filter')
            }
        },
        timerAfto() {
            this.$store.state.updates.update ? (this.$store.state.updates.timer = setInterval(() => this.refresh(), 30000)) : clearInterval(this.$store.state.updates.timer);
        },
        sendToParent(tableData){
            this.tariffPerformer = tableData
            this.$store.commit('pageData/setdataCount', this.tariffPerformer.length)
        },
    },
    computed: {
        fetchingNewData(){
                return this.$store.state.refresh.fetchingNewData
        }
    },
    watch: {
        fetchingNewData(val){
                if (val) {
                    let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
                    this.$http
                    .get(`${this.name}`, {params: param})
                    .then(res =>{
                        this.tariffPerformer = res.data
                        this.$store.commit('pageData/setdataCount', this.tariffPerformer.length)
                        this.$store.commit('REFRESH_DATA', false)
                    })
                }
            }
        }
}
</script>